.ImovelCard{
  width:290px!important;
  height:365px!important;
  transition:0.3s;
  margin-top:20px;
  margin-left:15px;
}
.ImovelCard:hover{
  transform: scale(1.05);
}

.ImovelCard .carousel.slide{
  padding:0;
}

.ImovelCard .slider-row{
  padding:10px 0;
  position: relative;
}

.ImovelCard .carousel.slide img{
  object-fit: cover;
  width:100%;
  height:180px;
  border-radius: 8px;
}


.ImovelCard .SwiperImovelCard{
  height:170px;
  padding:0!important;
  margin:0!important;
}

.ImovelCard .SwiperImovelCard .swiper-slide{
  width:100%!important;
}

.ImovelCard .SwiperImovelCard .swiper-slide img{
   object-fit: cover;
   width:100%;
   height:170px;
   border-radius: 5px;
}

.ImovelCard .slider-row .premiumFlag{
  position: absolute;
  top:10px;
  left:30px;
  z-index:2;
  width:80px;
  height:23px;
  background-color: #3E5E3F;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 8px 8px;
  font-size: 12px;
}

.ImovelCard .slider-row .fire{
  position: absolute;
  top:5px;
  left:-10px;
  width:50px;
  z-index:3;
}

.ImovelCard .slider-row .comissionContainer{
  position:absolute;
  bottom:10px;
  right:10px;
  z-index:2;
  width:120px;
}

.ImovelCard .SwiperImovelCard .swiper-button-prev, .ImovelCard .SwiperImovelCard .swiper-button-next{
  color:#fff;
  background-color:rgba(0,0,0,0);
  box-shadow:none;
  border-radius:50%;
  width:40px;
  height:40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:10;
}

.ImovelCard .SwiperImovelCard .swiper-button-next:after, .ImovelCard .SwiperImovelCard .swiper-button-prev:after{
  font-size: 26px!important;
  font-weight: 300!important;
}

.ImovelCard .SwiperImovelCard .more{
  background-color: #9c9c9c;
  color:#fff;
  height:100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 9;
  opacity: 0.6;
}

.ImovelCard .SwiperImovelCard .more .bg{
  background-position: center center;
  background-size: cover;
  position: absolute;
  top:0;
  bottom:0;
  right:0;
  left:0;
  width:100%;
  height:100%;
  z-index:2;
  opacity: 0.5;
  filter: blur(2px);
}

.ImovelCard .SwiperImovelCard .more p{
  font-size: 14px;
  color:#fff;
}

.ImovelCard .SwiperImovelCard .more i{
  font-size: 24px;
  color: rgba(255,255,255,0.87);
}

.ImovelCard h3{
  font-size: 16px;
  font-weight: 600;
}

.ImovelCard p{
  font-size: 12px;
  color:#888;
}

.ImovelCard .offer-container{
  margin-top:10px;
  text-align: left;
}

.ImovelCard .old-price{
  text-decoration: line-through;
  color:#888;
  font-size: 13px;
}

.ImovelCard .offer-price{
  color:#101010;
  font-size: 16px;
  border-radius:8px;
  font-weight:600;
}

.ImovelCard .offer-price small{
  margin-right: 2px;
}

.ImovelCard .until {
  font-size: 9px;
  padding:0;
  text-align: center;
  background-color: #fff;
  color:#3E5E3F;
  font-weight: 600;
  border-radius: 5px 0 0 0;
  height:15px;
  margin-top:2px;
  padding-top:1px;
}

.ImovelCard .comissionPercentage{
  font-size: 9px;
  padding:0;
  text-align: center;
  background-color: #fff;
  color:#3E5E3F;
  font-weight: 600;
  height:15px;
  margin-top:2px;
  padding-top:1px;
}

.ImovelCard .comissionPercentage small{
  font-size: 7px;
}

.ImovelCard .comission{
  font-size: 10px;
  padding:2px 0 0;
  font-weight: 600;
  text-align: center;
}

.ImovelCard .comission .fs{
  background-color: #3E5E3F;
  display: block;
  height:15px;
  border-radius: 0 5px 0 0;
  color:#fff;
}

.ImovelCard .comission .parc{
  color: #333;
  background-color: #f1ce41;;
  display: block;
  height:15px;
  border-radius: 5px 5px 0 0!important;
}

.ImovelCard .skills{
  margin:5px 0;
  font-size: 12px;
  font-weight: 600;
  padding:3px 5px;
}

.ImovelCard .skills .col{
  padding:0;
  display: flex;
  align-items: center;
  gap:5px;
}

.ImovelCard .skills .col i{
  color:#3E5E3F;
  font-size: 16px;
}

.ImovelCard .skills .col p{
  font-size: 12px;
  color:#333;
}

.ImovelCard .line{
  height:1px;
  background-color: #cdcdcd;
}

.ImovelCard .like{
  font-size: 18px;
  padding-top:10px;
  display: flex;
  justify-content: flex-end;
  color:#888;
}

.catalogResult .ImovelCard{
  margin-left:0!important;
  margin-bottom: 5px;
}

.textPatrocidade {
  position: relative;
  left: 228px;
  color: #333;
}

.ap {
  display: flex;
  align-items: center;
  gap: .5rem;
  padding-left: 0px !important;
}

.ap p {
  border: 1px solid #dcdcdc;
  border-radius: .8rem;
  padding: 0 .4rem !important;
  font-size: 11px !important;
  white-space: nowrap;
}

.EnterpriseCard .cover{
  height:260px;
  background-position:center center;
  background-size:cover;
  width:100%;
  border-radius:5px;
}

.EnterpriseCard .progress-container{
  display:flex;
  justify-content:space-between;

  small{
    font-size:9px;
    color:#101010;
  }

  span{
    font-size:10px;
  }
}

// @media(max-width:768px){
//   .ImovelCard.EnterpriseCard{
//     height:180px!important;
//     width: 100% !important
//   }
//   .EnterpriseCard .cover{
//     height:100px;
//   }
//   .EnterpriseCard .container{
//     padding:0 12px!important;
//   }
//   .EnterpriseCard .slider-row{
//     padding:0!important;
//   }
//   .EnterpriseCard .enterpriseInfoContainer{
//     padding:10px;
//   }
// }

.unity-tag-container{
  position:absolute!important;
  bottom:12px;
  left:0px;
  z-index:2;
  width:auto!important;
  display:flex;
  align-items:center;
}

.const-unit-cover{
  width:35px;
  height:35px;
  border-radius:50%;
  background-size:cover;
  background-position:center center;
  background-color: #fff;
  position:relative;
  z-index:1;
}

.tag-unity{
  background-color: #fff;
  padding:3px 5px 3px 15px;
  margin-left:-8px;
  border-radius:0 4px 4px 0;
  color:#333;
  height: 25px;
}

.ImovelCard .desatualizado{
  position:absolute;
  bottom:10px;
  background-color: #f1ce41;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  z-index:3;
  width:100%;
}

.ImovelCard .desatualizado p{
  color:#333;
  font-size:12px;
  font-weight:600;
}

.ImovelCard.no-update img{
  filter: grayscale(1);
  opacity:0.8;
}


.catalogResult.construction{
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));

  @media(max-width:768px){
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

.constructionCard .construction-card-name{
  font-size:12px;

  @media(max-width:768px){
    font-size:10px;
  }
}

.ImovelCard .row{
  margin:0!important;
}

.ImovelCard .high-text{
  display:none;
}

.ImovelCard.high-imovel{
  border:solid 4px #83c285;
  padding:5px;

  & .high-text{
    display:block;
    position:absolute;
    top:-28px;
    left:50%;
    transform: translate(-50%,0);
    background-color:#3E5E3F;
    padding:5px 15px 3px;
    border-radius: 15px 15px 0 0;
    color:#fff;
    text-transform: uppercase;
    white-space: nowrap;
    font-size:14px;
    font-weight:600;
  }

  & .SwiperImovelCard,
  & .SwiperImovelCard .swiper-slide img{
    height:150px!important;
  }
}


.EnterpriseCard .launch-enterprise{
  background-color: #3E5E3F;
  position: absolute;
  top:10px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 0 0 8px 8px;
  font-size: 16px;
  z-index:99;
  width: max-content;
  color:#fff;
}

.EnterpriseCard .pre-launch-enterprise{
  background-color: #3E5E3F;
  position: absolute;
  top:10px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 0 0 8px 8px;
  font-size: 16px;
  z-index:99;
  width: max-content;
  color:#fff;
}