#unitsListContainer .grey-background {
  background-color: #ccc;
}

#unitsListContainer .grey-background,
#unitsListContainer .white-background {
  padding:10px 0;
}

#unitsListContainer {
  border-radius:8px;
  background-color:#fff;
  padding:10px 20px 0;
  height:100%;
}

#unitsListContainer .unit-status{
  display:flex;
  gap:10px;
  justify-content:center;

  & .outlet{
    width:12px;
    margin-left:5px;
    margin-top: -4px;
  }
}

#unitsListContainer .unit-status p{
  font-size:18px;
}

#unitsListContainer .unitItem:hover{
  transform:scale(1.03);
  cursor:pointer;
}

#unitsListContainer .unitItem:hover p{
  text-decoration:underline;
}

#singleEnterprise .buttons-container{
  margin-top:30px;
  gap:10px;
  display:flex;

  button{
    background-color:#f4f4f4;
    color:#3E5E3F!important;
  }

  button:hover{
    background-color:#3E5E3F;
    color:#fff!important;
  }
}

#singleEnterprise .enterpriseLogo{
    width:140px;
    height:140px;
    object-fit: contain;
    margin-bottom:-20px;
}

#singleEnterprise .construction-logo{
  width:80px;
  height:80px;
  background-color:#fff;
  position:absolute;
  bottom:20px;
  left:20px;
  z-index:997;
  border-radius:50%;
  box-shadow:0 0 5px rgba(0,0,0,0.7);
  background-position: center center;
  background-size: cover;
}

#singleEnterprise .construction-contact{
  width:50px;
  height:50px;
  background-color:#fff;
  position:absolute;
  top:10px;
  right:10px;
  z-index:999;
  border-radius:50%;
  box-shadow:0 0 5px rgba(0,0,0,0.7);
  display:none;
  align-items:center;
  justify-content:center;
  font-size:32px;
  padding-right:5px;
  color:#3E5E3F;
}

#singleEnterprise .mobile-actions-container{
  display:none;
  gap:25px;
  align-items:center;
  justify-content:center;
  margin-top:-30px;
  position:relative;
  z-index:999;
}

#singleEnterprise .mobile-actions-container .enterprise-action{
  width:75px;
  height:75px;
  box-shadow:0 0 5px rgba(0,0,0,0.4);
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content:space-around;
  background-color:#fff;
  flex-direction:column;
  flex-wrap:wrap;
  padding-top:5px;
}

#singleEnterprise .mobile-actions-container .enterprise-action svg{
  font-size:28px;
  color:#3E5E3F;
  width:25px;
  height:25px;
  fill:#3E5E3F;
  margin-bottom:8px;
}

#singleEnterprise .mobile-actions-container .enterprise-action p{
  text-align:center;
  line-height:1;
  font-size:14px;
  height:30px;
}

#singleEnterprise .andamento-obra{
  max-width:680px;
  margin-top:50px;
}

#singleEnterprise .enterprise-skills-mobile{
  display:none;
  width:100%;
  padding:10px;
  justify-content:space-between;
  align-items:center;
  flex-wrap:wrap;
  margin-top:15px;
}

#singleEnterprise .enterprise-skills-mobile svg{
  font-size:28px;
  color:#3E5E3F;
  width:25px;
  height:25px;
  fill:#3E5E3F;
  margin-bottom:8px;
}

#singleEnterprise .enterprise-skills-mobile .highFeature{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  flex:1;
}

#singleEnterprise .enterprise-skills-mobile .highFeature i{
  font-size:32px;
}

@media(min-width:768px){
  .unitsToggle{
    display:none;
  }
}

@media(max-width:768px){
  #singleEnterprise .construction-logo{
    width:50px;
    height:50px;
    top:10px;
    bottom:auto;
    left:10px;
  }

  #singleEnterprise .gallery-modal .gallery-modal-body{
    padding:10px 0!important;
  }

  #singleEnterprise .enterprise-skills-mobile{
    display:flex;
  }

  #singleEnterprise  .acc-subtitle{
    display:none;
  }

  #singleEnterprise .andamento-obra{
    margin-top:0px;
  }

  #singleEnterprise .progressContainer{
    flex-direction:row;
    display:flex;
    gap:35px;
  }

  #singleEnterprise .enterprise-skills-desktop{
    display:none;
  }

  #singleEnterprise .progressContainer div:nth-child(2){
    margin-top:0!important;
  }

  #singleEnterprise .buttons-container{
    display:none;
  }

  #singleEnterprise .mobile-actions-container{
    display:flex;
  }

  #singleEnterprise .slidePhoto{
    height:340px!important;
  }

  #singleEnterprise .card-body{
    padding:0 10px 20px;
  }

  #singleEnterprise .construction-contact{
    display:flex;
  }

  #singleEnterprise .enterpriseLogo{
    display:none;
  }
  #unitsListContainer .unitsToggle{
    display:flex;
    justify-content: center;
  }

  #unitsListContainer .unitsToggle button{
    margin-top:-10px;
    margin-bottom:10px;
    font-size:18px;
    font-weight: 800;
    width:100%;
    position:absolute;
    height:47px;
    top:10px;
  }

  #unitsListContainer{
    position:fixed;
    top:75px;
    bottom:0;
    left:0;
    right:0;
    z-index:999;
    box-shadow:0 0 5px rgba(0,0,0,0.7);
    transition:0.3s;
    padding-top:55px;
  }

  #unitsListContainer.inactive{
    transform:translate(0, 85%);
  }

  #unitsListContainer.active{
    transform:translate(0, 0%);
    overflow-y:auto;
    padding-bottom:120px;
  }

  #unitsListContainer.active .unitsToggle{
    justify-content: flex-end;
  }

  #unitsListContainer.active .unitsToggle i{
    font-size:26px;
  }

  #unitsListContainer .cta{
    display:none;
  }

  #unitsListContainer .col-title{
    font-size:12px!important;
  }

  #singleEnterprise .buttons-container{
    margin-top:30px;
    gap:10px;
    flex-wrap:wrap;
  }

  #singleEnterprise .progressContainer{
    padding-left:15px!important;
    margin-top:20px;
  }

  #singleEnterprise .card-header{
    padding-left:5px!important;
  }
}

#actionUnitContainer{
  width:100%;
  max-width:480px;
  padding:5px 30px 20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  border-radius: 5px;
}

#actionUnitContainer .grey-background {
  background-color: #ccc;
}

#actionUnitContainer .grey-background,
#actionUnitContainer .white-background {
  padding:10px 0;
}

.constructionCard{
  cursor:pointer;
  transition:0.3s;
}

.constructionCard:hover{
  transform:scale(1.05);
}

.constructionHeader{
  width:100%;
  margin-bottom:40px;

  & span{
    background-color: #3E5E3F;
    padding:0 20px;
    height:40px;
    border-radius: 12px;
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;

    &:hover{
      background-color: #2e462f;
    }

    @media(max-width:768px){
      padding:0 10px;
      height:30px;
    }
  }
}

.constructionHeader .faixa{
  width:100%;
  height:100px;
  background-size: cover;
  background-position: center center;
  border-radius:8px 8px 0 0;
}

.constructionHeader .row{
  width:100%;
  padding:0 30px;
  display:flex;
  // justify-content:space-between;
  flex-direction:row;
}

.constructionHeader .row .col-right{
  width:220px;
  display:flex;
  flex-direction:column;
  gap:20px;
  align-items: center;
  font-size:18px;
  text-align:center;
}
.constructionHeader .row .col-left{
  width:300px;
  display:flex;
  flex-direction:column;
  justify-content: center;
  gap:10px;
  height:100%;
  padding-top:30px;
  align-items: flex-start;
  font-size:18px;
}

.constructionHeader .constructionCover{
  width:140px;
  height:140px;
  box-shadow:0 0 5px rgba(0,0,0,0.4);
  background-color:#fff;
  border-radius:50%;
  margin-top:-30px;
  background-position:center center;
  background-size:cover;
}

.constructionHeader .constructionWhats,
.constructionHeader .constructionPlace{
  display:flex;
  align-items: center;
  gap:4px;
}

@media(max-width:768px){
  .constructionHeader .constructionName{
    font-size:14px;
  }
}

.constructionHeader .constructionSite a,
.constructionHeader .constructionInstagram a{
  display:flex;
  align-items: center;
  gap:4px;

  @media(max-width:768px){
    display:none;
  }
}

.constructionHeader .constructionSite-mobile a,
.constructionHeader .constructionInstagram-mobile a{
  display:flex;
  align-items: center;
  gap:4px;

  @media(min-width:768px){
    display:none;
  }
}

@media(max-width:768px){
  .constructionHeader .row{
    align-items:center;
    margin:auto;
    padding:10px 0;
  }
  .constructionHeader .row .col-left{
    align-items: center;
    width:100%;
    gap:5px;
    margin-top:20px;
  }
  .constructionHeader{
    margin-bottom:0px;
  }

  .constructionHeader .faixa{
    height:60px;
  }
  .constructionHeader .constructionCover{
    width:80px;
    height:80px;
    margin-top:-20px;
  }
  .constructionHeader .row .col-right{
    gap:10px;
    width:180px;
  }
  .constructionHeader .row .col-left{
    width:calc(100% - 180px);
    font-size:13px;
    justify-content: flex-start;
    align-items:flex-start;
    padding:0;
  }
}

.grid-enterprise-gallery{
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
}

.grid-enterprise-gallery-doc{
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
}

.grid-enterprise-gallery-video{
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.grid-enterprise-gallery .item-gallery{
  width:100px;
  height:100px;
  background-position:center center;
  background-size:cover;
  border-radius:8px;
  cursor:pointer;
}

.grid-enterprise-gallery-video .item-gallery.video{
  width:120px;
  height:120px;
  display:flex;
  flex-direction:column;
  gap:0px;
  text-align:center;
  justify-content:flex-staet;
  align-items:center;
  border-radius:8px;

  i{
    font-size:40px;
  }
}

.grid-enterprise-gallery-doc .item-gallery-doc{
  width:100px;
  height:auto;
  background-position:center center;
  background-size:cover;
  border-radius:8px;
  cursor:pointer;
  display:flex;
  flex-direction:column;
  gap:5px;
  align-items:center;
  justify-content:center;
  text-align:center;

  i{
    font-size:30px;
  }

  small{
    font-size:10px;
  }
}

.grid-enterprise-gallery .item-gallery.active,
.grid-enterprise-gallery-video .item-gallery.active,
.grid-enterprise-gallery-doc .item-gallery-doc.active{
  border:solid 3px #3E5E3F;
}

.grid-buttons-galery{
  min-width: 680px;
}

.grid-buttons-galery .button-gallery{
  width:100%;
  background-color: #fff;
  border:solid 1px #3E5E3F;
  color:#3E5E3F!important;
}

.grid-buttons-galery .button-gallery:hover,
.grid-buttons-galery .button-gallery.active{
  color:#fff!important;
  background-color: #3E5E3F;
}


.singleEnterprise .modal.show {
  width:120px!important;
  max-width:100%;
}

@media(max-width:840px){
  .grid-buttons-galery{
    min-width: 100%!important;
    flex-wrap:wrap!important;
    gap:10px 0;
  }
}


.select-to-download{
  display:flex;
  gap:10px;
  align-items: center;
  border:solid 1px #cdcdcd;
  border-radius:5px;
  padding:4px;
  input{
    width:20px;
    height:20px;
  }
  label{
    font-size:14px;
  }
}

.select-to-download.active{
  border-color: #3E5E3F;
  background-color: #3e5e3f34;
  label{
    color:#3E5E3F;
  }
}

.gallery-enterprise-title{
  display:flex;
  justify-content: space-around;
  align-items: center;
  gap:10px;

  h3{
    font-size:18px;
  }
}

.enterprise-full-sell{
  width:180px;
  height:35px;
  display:flex;
  border-radius:10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  background-color:#2e462f;
  font-size: 14px;
  font-weight: 600;
}